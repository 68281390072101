<template>
  <div class="alert-body">
    <TheNav />

    <div class="content">
      <div class="alert">
        <p>
          付费课程商店电脑端在配置中，请先在手机或iPad上打开doyoudo微信公众号，点击菜单栏「付费内容」进行学习，谢谢！
        </p>
      </div>

      <p class="height"></p>
    </div>
  </div>

  <TheFooter />
</template>

<script>
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";
export default {
  setup() {
    components: {
      TheFooter, TheNav;
    }

    return {
      TheFooter,
      TheNav,
    };
  },
};
</script>

<style scoped>
.alert {
  width: 90%;
  height: 110px;
  padding: 20px 30px;
  background: #fef6ed;
  border: #f5cd84 solid 1px;
  border-radius: 9px;
}
.alert p {
  font-size: 1em;
  color: #46330a;
}
.alert-body {
  width: 100%;
  min-height: 800px;
  background: #f5f6f9f8;
}
.content {
  width: 1240px;
  margin: 0 auto;
}

.height {
  height: 120px;
}

@media screen and (max-width: 470px) {
  .content {
    width: 95%;
  }
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .content {
    width: 95%;
  }
}
</style>